<template>
  <div>
    <header class="header">
      <div class="homeTitle">
        <img alt="" src="@/assets/image/logo.png" width="30px">
        <p> 中国石油招标大数据图谱应用平台</p>
      </div>
      <div class="homeNav">
        <ul>
          <li>首页</li>
          <li @click="gotozstp">采购知识图谱</li>
          <li @click="gotocggx">采购关系</li>
          <li @click="gotoznfz">智能辅助评审</li>
          <li @click="gotoznwd">智能问答</li>
        </ul>
      </div>
    </header>
    <section class="bodyWrapper">
      <div class="bodyTitle">中国石油招标大数据图谱应用平台</div>
      <div class="bodyCard">
        <div class="cardItem"  @click="gotozstp">
          <div class="cardItemTitle">采购知识图谱</div>
          <div class="cardItemBody">构建形成面向各类数据使用人员的动态唯一标识知识图谱，发挥数据价值，打破业务边界，实现招投标业务的数字化、智能化，为全生命周期采购管理过程中的性能、质量、价格的有效匹配提供技术支持和数据服务。</div>
          <div class="cardItemBottom" >
            <ul>
              <li>数据智能提取</li>
              <li>数据关系挖掘</li>
              <li>打破业务边界</li>
            </ul>
          </div>
        </div>
        <div class="cardItem" @click="gotocggx">
          <div class="cardItemTitle">采购关系查询</div>
          <div class="cardItemBody">基于采购知识图谱的进行更深入地应用，基于两点查询所有的关系路径，基于所有路径，考虑路径的长度、复杂性和涉及的实体数量进行分析，识别路径中的关键实体、关键路径和备选路径，并分析各个路径的利弊，为决策提供支持。
          </div>
          <div class="cardItemBottom" >
            <ul>
              <li>路径识别</li>
              <li>路径分析</li>
              <li>助理最优决策</li>
            </ul>
          </div>
        </div>
        <div class="cardItem" @click="gotoznfz">
          <div class="cardItemTitle">智能辅助评审</div>
          <div class="cardItemBody">构建以“提升评标效率、辅助专家决策”为建设前提，通过NLP、OCR等智能技术能力，实现在评审环节智能化服务。包含招标智能解析、评审规则自动提取、围串标异常提醒、投标响应性检查等。实现从耗时耗力的人力审查过渡到以专家决策为主，AI数据分析为辅的智能时代。</div>
          <div class="cardItemBottom" >
            <ul>
              <li>智能解析文件</li>
              <li>辅助专家决策</li>
              <li>提升效率</li>
            </ul>
          </div>
        </div>
        <div class="cardItem" @click="gotoznwd">
          <div class="cardItemTitle">智能问答</div>
          <div class="cardItemBody">基于大模型强大的语义识别能力理解用户的问题，再结合知识图谱分析模型进行答案的查询分析，最终以文字或者图表等形式直观展示复杂数据或关系，并且支持多轮问答。智能问答可在编写采购方案、项目评审过程中、数据统计分析等多个场景下进行使用。
          </div>
          <div class="cardItemBottom" >
            <ul>
              <li>对话式问答</li>
              <li>多形式回答</li>
              <li>多场景应用</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bodyCard" style="margin-top: 28px;margin-bottom: 60px">
        <div class="bottomCardItem">
          <img alt="" src="@/assets/image/zcyh.png">
          <div class="bottomCardWrapper" ><p class="bottomCardTitle">注册用户</p><p  class="bottomCardNum">1356</p></div>
        </div>
        <div class="bottomCardItem">
          <img alt="" src="@/assets/image/qydw.png">
          <div class="bottomCardWrapper" ><p class="bottomCardTitle">企业单位</p><p  class="bottomCardNum">6789</p></div>
        </div>
        <div class="bottomCardItem">
          <img alt="" src="@/assets/image/gylsj.png">
          <div class="bottomCardWrapper" ><p class="bottomCardTitle">供应链数据</p><p  class="bottomCardNum">120万+</p></div>
        </div>
        <div class="bottomCardItem">
          <img alt="" src="@/assets/image/yxfw.png">
          <div class="bottomCardWrapper" ><p class="bottomCardTitle">有效服务</p><p  class="bottomCardNum">5万+</p></div>
        </div>
      </div>
      <div></div>
    </section>
  </div>
</template>

<script>
export default {
  name: "index",
  methods:{
    gotoznfz(){
      window.open('http://112.zhulong.com.cn:8103/znfzpb/#/login')
    },
    gotozstp(){
      const routeUrl = this.$router.resolve({ name: 'stgx' });
      window.open(routeUrl.href, '_blank');
    },
    gotoznwd(){
      const routeUrl = this.$router.resolve({ name: 'qa' });
      window.open(routeUrl.href, '_blank');
    },
    gotocggx(){
      const routeUrl = this.$router.resolve({ name: 'relation' });
      window.open(routeUrl.href, '_blank');
    }

  }
}
</script>

<style  lang="scss" scoped>
.header{
  height: 64px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  .homeTitle{
    display: flex;
    align-items: center;
    width: 350px;
    p{
      margin-left: 6px;
    }
    img{
      //margin-top:20px;
    }

  }
  .homeNav{
    width:900px;
  }
  .homeNav ul{
    display: flex;
    flex: 1;
    list-style: none;
    align-items: flex-start;
    justify-content: flex-end;
    li{
      cursor: pointer;
      padding: 0px 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    li:hover{
      color:#ffd04b
    }
  }
}
.bodyWrapper{
  //width: 1920px;
  height: 1016px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.20);
  background-image: url("~@/assets/image/home_bg.jpg");
  .bodyTitle{
    padding-top: 80px;
    padding-bottom: 60px;
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
  }
  .bodyCard{
    margin: 0 auto;
    width: 1200px;
    display: flex;
    flex-wrap:wrap;
    padding: 24px;
    //flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 10px;
    border: 2px solid #FFF;
    background: rgba(255, 255, 255, 0.40);
    .cardItem{
      cursor: pointer;
      width: 560px;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      background: #FFF;
      .cardItemTitle{
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      .cardItemBody{
        height: 120px;
        margin: 16px 0 8px 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
      .cardItemBottom{
        ul{
          display: flex;
          list-style: none;
          li{
            cursor: pointer;
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: 1px solid  #9FD3FD;
            margin-right: 6px;
          }
        }
      }
    }
    .bottomCardItem{
      display: flex;
      padding: 24px 20px;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      img{
        width: 60px;
        height: 60px;
      }
      .bottomCardWrapper{
        width: 100%;
        margin-left: 16px;
        .bottomCardTitle{
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }
        .bottomCardNum{
          margin-top: 10px;
          color: #244C89;
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
